h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 0.25em;
}

h1 {
  font-size: 3rem;
}

h2 {
  font-size: 2.5rem;
}

h3 {
  font-size: 2rem;
}

h4 {
  font-size: 1.5rem;
}

h5 {
  font-size: 1.25rem;
}

h6 {
  font-size: 1rem;
}

h1, h2, h3, h4, h5, h6, p {
    & + p {
    margin: 1rem 0 0;
  }
}