#board-members-section {
    background-color: $alice-blue;
}

.board-members {
    display: flex;
    justify-content: center;

    @include touch() {
        flex-wrap: wrap;
    }
}

.board-member {
    flex: 1 0 25rem;
    max-width: 25rem;
    margin: 1rem;

    &>.card-image {
        width: 25rem;
        height: 25rem;
        overflow: hidden;
    }

    & .card-content {
        & .media {
            @include touch() {
                margin-bottom: 0 !important;
            }
        }

        & .content {
            @include touch() {
                display: none;
            }
        }
    }
}

#about-ilg-section {
    margin: 2rem 0;

    & h3:nth-child(n+2) {
        margin: 1em 0 .25em;
    }
}