#events-section {
    background-color: $alice-blue;

    & .events > .column {
        flex: 1 0 450px;
    }

    & .event {
        height: 100%;
        display: flex;
        flex-direction: column;

        & > .card-content {
            // height: calc(100% - 1.5rem);
        }

        & .property.column.is-half {
            margin: 0 0 1rem;
            float: left;
            width: 50%;
        }

        & .event-description {
            clear: both;
            margin-top: auto;
        }

        & .card-footer {
            margin-top: auto;

            & .event-link {
                border-radius: 0;

                & + .event-link {
                    margin-left: 1px;
                }
            }
        }
    }
}

.card-header {
    background-color: $oxford-blue;
    color: $ghost-white;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-title {
        display: block;
        flex: 1 0 50%;
        margin: 0;
        background-color: $oxford-blue;
        color: $ghost-white;
    }

    & .event-date {
        text-align: right;
    }
}