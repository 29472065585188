#featured-section {
    background-color: $alice-blue;

    & .columns {
        flex-wrap: wrap;

        & .column {
            display: flex;
            flex: 1 0 40rem;
        }
    }
}