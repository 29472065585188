#upper-footer {
    background-color: $oxford-blue;
    color: #fff;

    & #footer-disclaimer {
        font-size: 0.875rem;
        color: #fff;

        &>.title {
            color: #fff;
        }
    }

    & #footer-contact {
        color: #fff;

        &>.title {
            color: #fff;
        }
    }
}