#form-section {
    & .cog-cognito.cog-form {
        & .cog-header {
            display: none;
        }

        & .cog-body {
            & .cog-label {
                font-family: $family-primary;
                font-weight: 400;
                font-size: 1rem;
            }

            & .cog-button {
                width: 100%;

                &.cog-button--primary {
                    background: $madder-lake;
                    color: $ghost-white;
                    border: none;
                    font-family: $family-primary;
                    font-size: 1rem;
                }
            }
        }
    }
}

.form-table {
    @include mobile() {
        width: 18rem;
        margin: 0 auto 1rem;
    }

    @include tablet() {
        width: 30rem;
        margin: 0 auto 1rem;
    }

    & .paypal-input {
        margin: 0 0 1rem;
    }
}

#paypal-section {
    & .content {
        max-width: 50rem;
        margin: 0 auto 4rem;
    }

    & input.button {
        @include mobile() {
            display: block;
            width: 20rem;
            margin: 0 auto;
        }

        @include tablet() {
            display: block;
            width: 30rem;
            margin: 0 auto;
        }
    }
}