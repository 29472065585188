// - - - - - - - - - - - - - - - - - - -
// - - base
// project defaults for base elements - h1-h6, p, a, etc.
html,
body {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100vh;
  max-height: 100vh;
}

html {
  overflow-y: hidden;
}

body {
  padding: 0 0;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  @media (min-width: $tablet) {
    padding: 0;
  }
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

ul {
  margin: 1rem 0;
  padding: 0;
  margin-left: 4rem;
  list-style: disc;
}

img,
video,
iframe {
  display: block;
  max-width: 100%;
  margin: 0 auto;
}

.full-width {
  width: 100%;
}

// blockquote {
//   margin: 1rem;
//   position: relative;

//   &:before,
//   &:after {
//     position: absolute;
//     display: block;
//     padding: 0.5rem;
//     font-size: 1.5rem;
//   }

//   &:before {
//     content: open-quote;
//     top: -1rem;
//     left: -1rem;
//   }

//   &:after {
//     content: close-quote;
//     bottom: -1rem;
//     right: 0;
//   }
// }

// svg {
//   max-width: 100%;
//   max-height: 100%;
//   transition: fill 500ms ease;
// }