#header {
    position: sticky;
    top: 0;
    background: #fff;
    z-index: 9999;
    border-bottom: 1px solid #cbd7da;

    & #site-logo {
        display: flex;
        align-items: center;
    }

    & navbar.navbar {
        justify-content: space-between;

        & #nav-menu {
            flex: 0;

            & ul {
                margin: 0;
            }
        }

        & .navbar-item {
            & a {
                color: $oxford-blue;
            }

            & img {
                @include mobile() {
                    max-height: 4rem;
                }

                @include tablet() {
                    max-height: 7rem;
                }
            }

            &.active,
            &:hover {
                & a {
                    color: $madder-lake
                }
            }
        }
    }
}

@include mobile() {
    .hero-body {
        & .title {
            font-size: 1.5rem;
            font-weight: 500;
        }

        & .subtitle {
            font-size: 1rem;
        }
    }
}