/* This is just used to extend the bulma hero base styles */
.hero {
    &.has-image {
        position: relative;
        background-color: #424242;
        overflow: hidden;

        &>.hero-image {
            position: absolute;
            z-index: 10;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-position: center;
            opacity: 0.25;

            &+.hero-body {
                position: relative;
                z-index: 11;

                & .title,
                & .subtitle {
                    color: $white;
                }
            }
        }
    }
}